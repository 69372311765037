// Addresses
import { getLaunchpadNFTAddress, getVaultAddress, getNFTFreeAddress, getGDTRouterAddress } from 'utils/addressHelpers'

// ABI
import { ChainId } from '@pancakeswap/sdk'
import { sidABI } from 'config/abi/SID'
import { SIDResolverABI } from 'config/abi/SIDResolver'
import { bCakeProxyABI } from 'config/abi/bCakeProxy'
import { chainlinkOracleABI } from 'config/abi/chainlinkOracle'
import { viemClients } from 'utils/viem'
import { Abi, PublicClient, WalletClient, getContract as viemGetContract } from 'viem'
import { Address, erc20ABI, erc721ABI } from 'wagmi'
import { nftFreeABI } from 'config/abi/nftFree'
import { gdtRouterABI } from 'config/abi/gdtRouter'
import { nftABI } from 'config/abi/nft'
import { vaultABI } from 'config/abi/vault'

export const getContract = <TAbi extends Abi | unknown[], TWalletClient extends WalletClient>({
  abi,
  address,
  chainId = ChainId.BSC,
  publicClient,
  signer,
}: {
  abi: TAbi
  address: Address
  chainId?: ChainId
  signer?: TWalletClient
  publicClient?: PublicClient
}) => {
  const c = viemGetContract({
    abi,
    address,
    publicClient: publicClient ?? viemClients[chainId],
    walletClient: signer,
  })
  return {
    ...c,
    account: signer?.account,
    chain: signer?.chain,
  }
}

export const getBep20Contract = (address: Address, signer?: WalletClient) => {
  return getContract({ abi: erc20ABI, address, signer })
}

export const getErc721Contract = (address: Address, walletClient?: WalletClient) => {
  return getContract({
    abi: erc721ABI,
    address,
    signer: walletClient,
  })
}

export const getChainlinkOracleContract = (address: Address, signer?: WalletClient, chainId?: number) => {
  return getContract({ abi: chainlinkOracleABI, address, signer, chainId })
}

export const getBCakeProxyContract = (proxyContractAddress: Address, signer?: WalletClient) => {
  return getContract({ abi: bCakeProxyABI, address: proxyContractAddress, signer })
}

export const getSidContract = (address: Address, chainId: number) => {
  return getContract({ abi: sidABI, address, chainId })
}

export const getUnsContract = (address: Address, chainId?: ChainId, publicClient?: PublicClient) => {
  return getContract({
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'addr',
            type: 'address',
          },
        ],
        name: 'reverseNameOf',
        outputs: [
          {
            internalType: 'string',
            name: 'reverseUri',
            type: 'string',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ] as const,
    chainId,
    address,
    publicClient,
  })
}

export const getSidResolverContract = (address: Address, signer?: WalletClient) => {
  return getContract({ abi: SIDResolverABI, address, signer })
}

export const getLaunchpadNFTContract = (signer?: WalletClient, chainId?: number) => {
  return getContract({
    abi: nftABI,
    address: getLaunchpadNFTAddress(chainId),
    signer,
    chainId,
  })
}

export const getVault = (signer?: WalletClient, chainId?: number) => {
  return getContract({
    abi: vaultABI,
    address: getVaultAddress(chainId),
    signer,
    chainId,
  })
}

export const getNFTFreeContract = (signer?: WalletClient, chainId?: number) => {
  return getContract({
    abi: nftFreeABI,
    address: getNFTFreeAddress(chainId),
    signer,
    chainId,
  })
}

export const getGDTRouter = (signer?: WalletClient, chainId?: number) => {
  return getContract({
    abi: gdtRouterABI,
    address: getGDTRouterAddress(chainId),
    signer,
    chainId,
  })
}
