export default {
  multiCall: {
    1: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4: '0xcA11bde05977b3631167028862bE2a173976CA11',
    5: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56: '0xcA11bde05977b3631167028862bE2a173976CA11',
    97: '0xcA11bde05977b3631167028862bE2a173976CA11',
    280: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    324: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
  },
  launchpadNft: {
    56: '0xe6c9D0C24cf544A9F9B81fae880F92e5092A79bD',
  },
  vault: {
    56: '0x26864AA298356ca3Aa975b7C987c16F4d35d8329',
  },
  nftFree: {
    56: '0x8B635890b9D03cCa562D34447fC67aC091D3E10A',
  },
  gdtRouter: {
    56: '0x028b8F8CF5CEF422aF1c3C7807f4159cBf51Ec11',
  },
} as const satisfies Record<string, Record<number, `0x${string}`>>
