import { languageList, useTranslation } from '@pancakeswap/localization'
import { footerLinks, Menu as UikitMenu, NextLinkFromReactRouter, useModal, Logo } from '@pancakeswap/uikit'
import USCitizenConfirmModal from 'components/Modal/USCitizenConfirmModal'
// import { NetworkSwitcher } from 'components/NetworkSwitcher'
import Link from 'next/link'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import useTheme from 'hooks/useTheme'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { usePhishingBanner } from '@pancakeswap/utils/user'
import { IdType } from 'hooks/useUserIsUsCitizenAcknowledgement'
// import GlobalSettings from './GlobalSettings'
// import { SettingsMode } from './GlobalSettings/types'
import { useGetToken } from 'state/token/hooks'
import { GDT_BSC } from '@pancakeswap/tokens'
import { formatNumber } from '@pancakeswap/utils/formatBalance'
import { useMenuItems } from './hooks/useMenuItems'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'


// import Lottery from '../Lottery'
// import { useHideSavingMenu } from './hooks/useHideSavingMenu'

const ContentHeader = styled.div`
  width: fit-content;
  margin: 0px 10px 0px 0px;
`

const ItemLinkHeader = styled(Link)`
  width : fit-content;
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  padding: 7.5px 12.5px 7.5px 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`

const ImgToken = styled.img`
  width : 22px;
  height : 22px;
`

const PriceToken = styled.div`
  color: ${({ theme }) => theme.colors.color};
  font-family: SUISSE_BOLD;
  margin : 0px 0px 0px 7.5px;
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.25);
`

const LinkComponent = (linkProps) => {
  return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
}

const Menu = (props) => {
  const { chainId } = useActiveChainId()
  const { isDark, setTheme } = useTheme()
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const gdt = useGetToken(GDT_BSC.symbol)
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const [onUSCitizenModalPresent] = useModal(
    <USCitizenConfirmModal title={t('PancakeSwap Perpetuals')} id={IdType.PERPETUALS} />,
    false,
    false,
    'usCitizenConfirmModal',
  )
  const [showPhishingWarningBanner] = usePhishingBanner()

  // const { isHideSavingMenu } = useHideSavingMenu()
  const menuItemsCache = useMenuItems(onUSCitizenModalPresent)
  const menuItems = [...menuItemsCache].filter((item) => !item?.href?.includes('/saving'))

  // if (!isHideSavingMenu) menuItems = [...menuItemsCache]

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])


  return (
    <>
      <UikitMenu
        linkComponent={LinkComponent}
        rightSide={
          <>
            <ContentHeader>
              {/* <ItemLinkHeader href="/token-price">
                <ImgToken src="/images/mining/tokenMN.png" />
                <PriceToken>${formatNumber(gdt?.price ?? 0, 0, 6)}</PriceToken>
              </ItemLinkHeader> */}
            </ContentHeader>
            <UserMenu />
          </>
        }
        logo={<Logo href="/logo.png" />}
        chainId={chainId}
        banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy CAKE')}
        buyCakeLink="https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82&chainId=56"
        {...props}
      />
    </>
  )
}

export default Menu
