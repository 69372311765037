import { Abi, Address } from 'viem'
import { erc20ABI, useWalletClient } from 'wagmi'

import { useActiveChainId } from 'hooks/useActiveChainId'

import { useMemo } from 'react'
import { getMulticallAddress } from 'utils/addressHelpers'
import {
  getBCakeProxyContract,
  getContract,
  getLaunchpadNFTContract,
  getVault,
  getNFTFreeContract,
  getGDTRouter,
} from 'utils/contractHelpers'

import { ChainId, WNATIVE, pancakePairV2ABI } from '@pancakeswap/sdk'
import { multicallABI } from 'config/abi/Multicall'
import { erc20Bytes32ABI } from 'config/abi/erc20_bytes32'
import { wbethBscABI } from 'config/abi/wbethBSC'
import { wbethEthABI } from 'config/abi/wbethETH'
import { WBETH } from 'config/constants/liquidStaking'

import { erc721CollectionABI } from 'config/abi/erc721collection'
import { wethABI } from 'config/abi/weth'

export const useERC20 = (address: Address) => {
  return useContract(address, erc20ABI)
}

export const useErc721CollectionContract = (collectionAddress: Address) => {
  return useContract(collectionAddress, erc721CollectionABI)
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
export function useContract<TAbi extends Abi>(
  addressOrAddressMap?: Address | { [chainId: number]: Address },
  abi?: TAbi,
) {
  const { chainId } = useActiveChainId()
  const { data: walletClient } = useWalletClient()

  return useMemo(() => {
    if (!addressOrAddressMap || !abi || !chainId) return null
    let address: Address | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract({
        abi,
        address,
        chainId,
        signer: walletClient,
      })
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, abi, chainId, walletClient])
}

export function useTokenContract(tokenAddress?: Address) {
  return useContract(tokenAddress, erc20ABI)
}

export function useWNativeContract() {
  const { chainId } = useActiveChainId()
  return useContract(chainId ? WNATIVE[chainId]?.address : undefined, wethABI)
}

export function useWBETHContract() {
  const { chainId } = useActiveChainId()

  const abi = useMemo(
    () => ([ChainId.ETHEREUM, ChainId.GOERLI].includes(chainId) ? wbethEthABI : wbethBscABI),
    [chainId],
  )

  return useContract(chainId ? WBETH[chainId] : undefined, abi)
}

export function useBytes32TokenContract(tokenAddress?: Address) {
  return useContract(tokenAddress, erc20Bytes32ABI)
}

export function usePairContract(pairAddress?: Address) {
  return useContract(pairAddress, pancakePairV2ABI)
}

export function useMulticallContract() {
  const { chainId } = useActiveChainId()
  return useContract(getMulticallAddress(chainId), multicallABI)
}

export function useBCakeProxyContract(proxyContractAddress: Address) {
  const { data: signer } = useWalletClient()
  return useMemo(
    () => proxyContractAddress && getBCakeProxyContract(proxyContractAddress, signer),
    [signer, proxyContractAddress],
  )
}

export const useLaunchpadNFTContract = ({ chainId: chainId_ }: { chainId?: ChainId } = {}) => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()
  return useMemo(() => getLaunchpadNFTContract(signer, chainId_ ?? chainId), [signer, chainId_, chainId])
}

export const useVault = ({ chainId: chainId_ }: { chainId?: ChainId } = {}) => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()
  return useMemo(() => getVault(signer, chainId_ ?? chainId), [signer, chainId_, chainId])
}

export const useNFTFreeContract = ({ chainId: chainId_ }: { chainId?: ChainId } = {}) => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()
  return useMemo(() => getNFTFreeContract(signer, chainId_ ?? chainId), [signer, chainId_, chainId])
}

export const useGdtRouter = ({ chainId: chainId_ }: { chainId?: ChainId } = {}) => {
  const { chainId } = useActiveChainId()
  const { data: signer } = useWalletClient()

  return useMemo(() => getGDTRouter(signer, chainId_ ?? chainId), [signer, chainId_, chainId])
}
