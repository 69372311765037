import { ContextApi } from '@pancakeswap/localization'
import {
  DropdownMenuItems,
  MenuItemsType,
  Market,
  Referral,
  MinitingIcon,
  Airdrop,
  WalletIcon,
} from '@pancakeswap/uikit'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const Config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) => {
  return [
    {
      label: t('Market'),
      icon: Market,
      href: '/',
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Airdrop'),
      icon: Airdrop,
      href: '/airdrop',
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Minting'),
      icon: MinitingIcon,
      href: '/minting',
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Collection'),
    //   href: `${nftsBaseUrl}/collections`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   image: '/images/decorations/nft.png',
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
    {
      label: t('Referral'),
      icon: Referral,
      href: '/referral',
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Wallet'),
      icon: WalletIcon,
      href: '/wallet',
      showItemsOnMobile: false,
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },

    // {
    //   label: t('History'),
    //   icon: HistoryIcon,
    //   href: '/transaction',
    //   showItemsOnMobile: false,
    //   items: [].map((item) => addMenuItemSupported(item, chainId)),
    // },
  ].map((item) => addMenuItemSupported(item, chainId))
}
export default Config
