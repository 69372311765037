import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { VaultKey } from 'state/types'

export interface Addresses {
  [chainId: number]: `0x${string}`
}

export const getAddressFromMap = (address: Addresses, chainId?: number): `0x${string}` => {
  return address[chainId] ? address[chainId] : address[ChainId.BSC]
}

export const getAddressFromMapNoFallback = (address: Addresses, chainId?: number): `0x${string}` | null => {
  return address[chainId]
}

export const getMulticallAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.multiCall, chainId)
}

export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddressFromMap(addresses[vaultKey])
}

export const getLaunchpadNFTAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.launchpadNft, chainId)
}

export const getVaultAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.vault, chainId)
}

export const getNFTFreeAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.nftFree, chainId)
}

export const getGDTRouterAddress = (chainId?: number) => {
  return getAddressFromMap(addresses.gdtRouter, chainId)
}
